<template>
  <div class="text-sm font-bold bg-slate-900 rounded-t-md shadow-md shadow-black/10">
    <slot/>
  </div>
</template>

<script>
export default defineComponent({
  name: 'ListHeader',
});
</script>
